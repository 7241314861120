@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&family=Open+Sans&display=swap');
@import './base.css';
@import './normalize.css';

:root {
	--theme-primary: #0098da;
	--white: #ffffff;
	--black: #041821;
	--accent: #0400da;
	--gray: #a0a0a0;
	--font-primary: 'Montserrat';
	--font-secondary: 'Open Sans';
	--font-lg: 2.84em;
	--font-x-md: 1.82em;
	--font-md: 1.11em;
	--font-sm: 0.885em;
	--font-x-sm: 0.97em;
}

::selection {
	background: var(--theme-primary);
	color: var(--white);
	/* WebKit/Blink Browsers */
}

::-moz-selection {
	background: var(--theme-primary);
	color: var(--white);
	/* Gecko Browsers */
}

::-webkit-input-placeholder {
	color: var(--gray);
	opacity: 1;
}

:-moz-placeholder {
	color: var(--gray);
	opacity: 1;
}

::-moz-placeholder {
	color: var(--gray);
	opacity: 1;
}

:-ms-input-placeholder {
	color: var(--gray);
	opacity: 1;
}

body {
	font-family: var(--font-secondary), -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	font-weight: 400;
	margin: 0;
	/* background: #fff8f0; */
	background: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
	font-family: var(--font-primary);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
	image-rendering: crisp-edges;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

@media screen and (max-width: 935px) {
	:root {
		--sidebar-width: 0px !important;
	}
}

@media (min-width: 767px) {
	.w-md-auto {
		width: auto !important;
	}
}

.fadeIn {
	animation: fadeIn;
	animation-fill-mode: both;
	animation-duration: 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@media (max-width: 990px) {
	:root {
		--font-lg: 1.84em;
		--font-x-lg: 1.92em;
		--font-x-md: 1.24em;
		--font-md: 1em;
		--font-sm: 0.8375em;
		--font-x-sm: 0.92em;
	}

	header {
		display: block !important;
		padding: 7rem 0 !important;
		min-height: auto !important;
	}

	header h1,
	header p {
		text-align: center;
	}
}

.w-md-auto {
	width: 100%;
}

.none-button {
	padding: 0;
	border: none;
	background: transparent;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: var(--accent) !important;
}

.btn-primary {
	border: none !important;
	background: var(--theme-primary) !important;
	border-radius: 3px !important;
	transition: all 0.4s ease;
	color: #fff;
}

.btn-accent {
	border: none;
	background: var(--accent);
	border-radius: 3px;
	transition: all 0.4s ease;
	color: #fff;
}
.btn-accent:hover,
.btn-primary:hover {
	opacity: 0.7;
}
